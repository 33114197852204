/* eslint-disable import/prefer-default-export */
import transport from '@/services/api/multi-content/transport';
import * as methods from '@/services/api/constants';
import {
  IFileGetRequest,
  IMulticontentGetResponseData,
} from './types';
import { IVideo } from '@/domains/multicontent';
import { proxyURLOnLocalhost } from '@/helpers/url';

const FILES_BUCKET_ADDRESS = process.env.VUE_APP_FILES_BUCKET_ADDRESS || '';
const FILES_PROXY_ADDRESS = process.env.VUE_APP_FILES_PROXY_ADDRESS || '';

export const multicontentSessionGet = ({ playerSessionId, ...options }: { playerSessionId: number }):
Promise<IMulticontentGetResponseData> => transport({
  url: `/v3/multicontents/player/${playerSessionId}`,
  method: methods.HTTP_GET,
  ...options,
});

interface IMulticontentGetRequestPayload {
  playerSessionId: string;
  videos: Pick<IVideo, 'videoId' | 'progress'>[];
  progressMeta?: string,
}

export const multicontentSessionUpdate = (
  { playerSessionId, data, ...options }: { playerSessionId: string, data: IMulticontentGetRequestPayload },
): Promise<any> => transport({
  url: `/v3/multicontents/player/${playerSessionId}/progress`,
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const multicontentSessionComplete = (
  { playerSessionId, ...options }: { playerSessionId: string },
): Promise<any> => transport({
  url: `/v3/multicontents/player/${playerSessionId}/complete`,
  method: methods.HTTP_POST,
  ...options,
});

/**
 * Вычитать ссылку на файл
 * @param {number} playerSessionId
 * @param {string} mediaId
 * @return {Promise<string>}
 */
export const fileGet = (
  { playerSessionId, mediaId }: IFileGetRequest,
): Promise<{ downloadUrl: string }> => transport({
  url: `/v3/multicontents/player/${playerSessionId}/media/${mediaId}/download`,
  method: methods.HTTP_GET,
});

/**
 * Загрузить файл пользователю на компьютер по ссылке
 * @param {string} downloadUrl
 * @return {Promise<void>}
 */
export const uploadFile = async (downloadUrl: string) => {
  const config = {
    method: 'GET',
  };

  const currentUrl = proxyURLOnLocalhost({
    url: downloadUrl,
    bucketAddress: FILES_BUCKET_ADDRESS,
    proxyAddress: FILES_PROXY_ADDRESS,
  });

  return fetch(currentUrl, config);
};

export { multicontentCheckNewVersion } from '@/services/api/multi-content/multicontentCheckNewVersion';
