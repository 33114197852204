import { ref, computed, Ref } from 'vue';

import * as MultiContentService from '@/services/api/multi-content';

import {
  IVideo, LessonContentItem, LessonContentItemBase, LessonContentItemType, LessonContentItemVideo,
} from '@/domains/multicontent';

import { useFeatureFlags } from '@/plugins/feature-flags';
import useMultiContentBody from '@/components/multicontent/composables/useMultiContentBody';

import { PlayerSessionStatus, UUID } from '@/domains/common';

interface IAtomContent {
  title: string;
  description: string;
  body: LessonContentItem[];
  providers: [];
  id: UUID;
}

interface IVideoExtend extends IVideo {
  isRewind?: boolean;
}

interface IUseMulticontentPlayer {
  playerSessionId: Ref<number>;
}

// Процент прогресса видео, после которого оно считается просмотренным
const MAX_VIDEO_PROGRESS: number = 90;

export default function useMulticontentPlayer({ playerSessionId }: IUseMulticontentPlayer) {
  const { flags } = useFeatureFlags();
  const { parseContentBody, prepareContentBody } = useMultiContentBody({ features: flags });

  const rawContent = ref<any>();
  const content = ref<IAtomContent>();
  const isLoading = ref<Boolean>(false);
  const isUpdating = ref<Boolean>(false);
  const videoProgress = ref<IVideoExtend[]>([]);
  const isPageScrolledToEnd = ref<boolean>(false);
  const isUpToDate = ref<boolean>(false);

  const sessionStatus = computed<PlayerSessionStatus>({
    get(): PlayerSessionStatus {
      return rawContent.value?.progress?.status;
    },
    set(newValue: PlayerSessionStatus) {
      rawContent.value.progress.status = newValue;
    },
  });

  const isVideosCompleted = computed(() => videoProgress.value
    ?.every(({ progress }: { progress: number }) => progress >= MAX_VIDEO_PROGRESS));
  const isSessionStarted = computed(() => sessionStatus.value !== PlayerSessionStatus.NEW);
  const isSessionActive = computed(() => sessionStatus.value === PlayerSessionStatus.ACTIVE);
  const isSessionCompleted = computed(() => sessionStatus.value === PlayerSessionStatus.COMPLETED);

  const isCompleted = computed(() => isSessionCompleted.value || (videoProgress.value?.length > 0
    ? isVideosCompleted.value && isPageScrolledToEnd.value
    : isPageScrolledToEnd.value));

  const completeSession = async () => {
    if (isSessionCompleted.value) {
      return;
    }

    sessionStatus.value = PlayerSessionStatus.COMPLETED;

    try {
      if (playerSessionId) {
        await MultiContentService.multicontentSessionComplete({
          playerSessionId: String(playerSessionId.value),
        });
      }
    } catch (e) {
      console.error(e);
      sessionStatus.value = PlayerSessionStatus.ACTIVE;
    }
  };

  const onUpdateHandler = async (videoProgressValue: IVideo[]) => {
    isUpdating.value = true;
    try {
      if (playerSessionId && videoProgressValue.length) {
        await MultiContentService.multicontentSessionUpdate({
          playerSessionId: String(playerSessionId.value),
          data: {
            playerSessionId: String(playerSessionId.value),
            videos: videoProgressValue
              .map(({ videoId, progress, duration }) => ({ videoId, progress, duration })),
          },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      isUpdating.value = false;
    }
  };

  const fetchSession = async () => {
    isLoading.value = true;

    try {
      rawContent.value = await MultiContentService.multicontentSessionGet({
        playerSessionId: playerSessionId.value,
      });

      const body = JSON.parse(rawContent.value.body);
      const items = Array.isArray(body) ? body : [];
      const videos = items.filter((item: LessonContentItemBase) => item.type === LessonContentItemType.VIDEO);

      if (!rawContent.value.progress?.videos?.length) {
        videoProgress.value = videos.map((item: LessonContentItemVideo) => ({
          videoId: item.options.videoId,
          progress: 1,
          duration: 0,
        }));

        await onUpdateHandler(videoProgress.value);
      } else {
        videoProgress.value = rawContent.value.progress.videos;
      }

      content.value = {
        body: parseContentBody(prepareContentBody(rawContent.value)),
        title: rawContent.value.title,
        providers: rawContent.value.providers,
        description: rawContent.value.description,
        id: rawContent.value.id,
      };
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  const onUpdateVideoProgressHandler = async (
    { videoId, progress, duration }:
      { videoId: UUID; progress: number; duration: number },
  ) => {
    const videoIndex = videoProgress.value
      ?.findIndex((item: { videoId: UUID; progress: number; duration: number }) => item.videoId === videoId);

    if (videoIndex >= 0) {
      if (videoProgress.value[videoIndex].progress > MAX_VIDEO_PROGRESS) {
        return;
      }

      if (videoProgress.value[videoIndex].progress > progress && !videoProgress.value[videoIndex].isRewind) {
        videoProgress.value[videoIndex].isRewind = true;

        const contentItemIndex = content.value?.body?.findIndex(
          (item) => (item as LessonContentItemVideo)?.options?.videoId === videoId,
        ) ?? -1;

        if (contentItemIndex >= 0) {
          const playback = duration * (videoProgress.value[videoIndex].progress / 100);

          const newItem = { ...(content.value?.body[contentItemIndex] as LessonContentItemVideo) };
          newItem.options.playback = playback;
          const tempContBody = JSON.parse(rawContent.value?.body);
          tempContBody.splice(contentItemIndex, 1, newItem);
        }
      }

      if (videoProgress.value[videoIndex].progress < progress) {
        videoProgress.value.splice(videoIndex, 1, { ...videoProgress.value[videoIndex], progress });

        await onUpdateHandler(videoProgress.value);
      }
    }

    if (isVideosCompleted.value && isPageScrolledToEnd.value) {
      await completeSession();
    }
  };

  const onCompleteHandler = async (entity: IntersectionObserverEntry[]) => {
    if (entity[0]?.isIntersecting) {
      isPageScrolledToEnd.value = true;

      if (isVideosCompleted.value) {
        await completeSession();
      }
    }
  };

  const checkVersion = async () => {
    try {
      const { hasNewVersion } = await MultiContentService.multicontentCheckNewVersion({
        playerSessionId: String(playerSessionId.value),
      });

      isUpToDate.value = !hasNewVersion;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    content,
    isLoading,
    isUpdating,
    isCompleted,
    isUpToDate,
    isSessionStarted,
    isSessionActive,
    isSessionCompleted,
    completeSession,
    onUpdateHandler,
    onUpdateVideoProgressHandler,
    fetchSession,
    onCompleteHandler,
    checkVersion,
    sessionStatus,
  };
}
