
import {
  defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import Loader from '@/components/ui/Loader.vue';
import { AtomType } from '@/domains/atom';
import { Names } from '@/plugins/vue-router';
import { PlayerSessionStatus } from '@/domains/common';
import useMulticontentPlayer from '@/components/multicontent/composables/useMulticontentPlayer';

export default defineComponent({
  name: 'AssignmentMulticontentGateway',

  components: {
    Loader,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { playerSessionId } = toRefs(props);

    const router = useRouter();

    const {
      sessionStatus, fetchSession,
    } = useMulticontentPlayer({ playerSessionId });

    onMounted(async () => {
      await fetchSession();

      if (!sessionStatus) return;

      if ([PlayerSessionStatus.NEW, PlayerSessionStatus.COMPLETED].includes(sessionStatus.value)) {
        router.replace({
          name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
          params: {
            atomType: AtomType.MULTICONTENT,
          },
        });
      } else {
        router.replace({
          name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTICONTENT_PLAYER,
        });
      }
    });
  },
});
