import { UUID } from '@/domains/common';
import transport from '@/services/api/multi-content/transport';
import * as methods from '@/services/api/constants';

export interface IMulticontentCheckNewVersionRequest {
  playerSessionId: UUID;
}

export interface IMulticontentCheckNewVersionResponse {
  hasNewVersion: boolean;
}

// eslint-disable-next-line max-len,vue/max-len
export async function multicontentCheckNewVersion(request: IMulticontentCheckNewVersionRequest): Promise<IMulticontentCheckNewVersionResponse> {
  return transport({
    url: `/v3/multicontents/player/${request.playerSessionId}/check_version`,
    method: methods.HTTP_GET,
  });
}
