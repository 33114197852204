import { PlayerSessionId, PlayerSessionStatus, UUID } from '@/domains/common';
import { TextAlign } from '@/domains/multicontent/text-align';
import { IUploadedMedia } from '@/domains/multicontent/media';

export interface IVideo {
  videoId: UUID,
  progress: number,
  duration: number,
}

export interface IMulticontentGetResponseData {
  id: string;
  title: string;
  description: string;
  body: string;
  duration: number;
  progress: {
    playerSessionId: PlayerSessionId;
    status: PlayerSessionStatus;
    progressMeta: string;
    videos: IVideo[];
  }
}

export enum LessonContentItemType {
  TEXT = 'TEXT',
  RICH_TEXT = 'RICH_TEXT',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  DIVIDER = 'DIVIDER',
  FILES = 'FILES',
  YOUTUBE = 'YOUTUBE',
  LINK = 'LINK'
}

interface LessonContentItemBaseOptions {}
export interface LessonContentItemBase {
  type: LessonContentItemType;
  value: string;
  options: LessonContentItemBaseOptions;
}

interface LessonContentItemTextOptions extends LessonContentItemBaseOptions {
  align?: TextAlign;
}

interface LessonContentItemFileOptions extends IUploadedMedia {}

export interface LessonContentItemText extends LessonContentItemBase {
  type: LessonContentItemType.TEXT;
  options: LessonContentItemTextOptions;
}

interface LessonContentItemRichTextOptions extends LessonContentItemBaseOptions {
  html: string;
}
export interface LessonContentItemRichText extends LessonContentItemBase {
  id: UUID;
  type: LessonContentItemType.RICH_TEXT;
  options: LessonContentItemRichTextOptions;
}

interface LessonContentItemDividerOptions extends LessonContentItemBaseOptions {}
export interface LessonContentItemDivider extends LessonContentItemBase {
  type: LessonContentItemType.DIVIDER;
  options: LessonContentItemDividerOptions;
}

interface LessonContentItemImageOptions extends LessonContentItemBaseOptions {}
export interface LessonContentItemImage extends LessonContentItemBase {
  type: LessonContentItemType.IMAGE;
  options: LessonContentItemImageOptions;
}

interface LessonContentItemAudioOptions extends LessonContentItemBaseOptions {}
export interface LessonContentItemAudio extends LessonContentItemBase {
  type: LessonContentItemType.AUDIO;
  options: LessonContentItemAudioOptions;
}

export enum LessonContentItemVideoStatus {
  INITIAL = 'initial',
  ON_TRANSCODING = 'on_transcoding',
  CREATED = 'created',
  OK = 'ok',
  INVALID = 'invalid',
}

interface LessonContentItemVideoOptions extends LessonContentItemBaseOptions {
  status: LessonContentItemVideoStatus;
  videoId: UUID;
  playback?: number;
}
export interface LessonContentItemVideo extends LessonContentItemBase {
  type: LessonContentItemType.VIDEO;
  options: LessonContentItemVideoOptions;
}
export type YoutubeAllowedProp =
  | 'accelerometer'
  | 'autoplay'
  | 'clipboard-write'
  | 'encrypted-media'
  | 'gyroscope'
  | 'picture-in-picture';
export interface LessonContentItemYoutubeOptions extends LessonContentItemBaseOptions {
  startSeconds: number; // Количество секунд с начала видео с которого нужно начать просмотр
  fullUrl: string; // полный путь до видео, который сохранил пользователь
  title: string;
  frameborder: number;
  allowfullscreen: boolean;
  allow: YoutubeAllowedProp[];
}

export interface LessonContentItemYoutube extends LessonContentItemBase {
  type: LessonContentItemType.YOUTUBE;
  options: LessonContentItemYoutubeOptions;
}

export interface LessonContentItemFile extends LessonContentItemBase {
  type: LessonContentItemType.FILES;
  options: LessonContentItemFileOptions;
}

export interface LessonContentItemLink extends LessonContentItemBase {
  type: LessonContentItemType.LINK;
  options: LessonContentItemFileOptions;
}

export type LessonContentItem =
  LessonContentItemText
  | LessonContentItemRichText
  | LessonContentItemDivider
  | LessonContentItemImage
  | LessonContentItemAudio
  | LessonContentItemVideo
  | LessonContentItemYoutube;

export type LessonContent = LessonContentItem[];

// ----------------------------------------
