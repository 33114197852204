import { LessonContent, LessonContentItemType } from '@/domains/multicontent';
import { IFeatureFlags } from '@/plugins/feature-flags/types';
import { IAssignmentMultiContent } from '@/domains/assignment';

interface IUseMultiContentBody {
  features: IFeatureFlags;
}

export function parseMulticontentBody(body: string): LessonContent {
  try {
    const items = JSON.parse(body);
    return Array.isArray(items) ? items : [];
  } catch (e: any) {
    console.error({ content: e.message });
    return [];
  }
}

export default function useMultiContentBody({ features }: IUseMultiContentBody) {
  const isRichTextAvailable = features.multi_content_richtext;
  const isTextAvailable = features.multi_content_text;

  const disabledTypes: LessonContentItemType[] = [];
  if (!isRichTextAvailable) {
    disabledTypes.push(LessonContentItemType.RICH_TEXT);
  }
  if (!isTextAvailable) {
    disabledTypes.push(LessonContentItemType.TEXT);
  }

  const parseContentBody = (body: string): LessonContent => {
    const items = parseMulticontentBody(body);
    return items.filter((value) => !disabledTypes.includes(value.type));
  };

  const prepareContentBody = (value: IAssignmentMultiContent): string => {
    const body = JSON.parse(value.body);
    const items = Array.isArray(body) ? body : [];

    return JSON.stringify(
      items.map((item) => {
        const result = { ...item };

        if (item.type === LessonContentItemType.FILES) {
          result.options = value.media.find((media) => media.id === item.value[0]);
        }

        return result;
      }),
    );
  };

  return { parseContentBody, prepareContentBody };
}
